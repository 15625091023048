<template>
  <div class="limit">
    <div class="img">
      <img src="./img/limit.png" alt="" />
    </div>
    <div class="info">抱歉，您暂无当前页面的访问权限，请联系管理员；</div>
    <linkInfo></linkInfo>
  </div>
</template>

<script>
import linkInfo from "./linkInfo";
export default {
  name: "limit",
  data() {
    return {};
  },
  components: {
    linkInfo,
  },
};
</script>

<style lang="less" scoped>
.limit {
  height: 100vh;
  padding-top: 198px;
  box-sizing: border-box;
  .img {
    width: 400px;
    height: 400px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .info {
    width: 400px;
    margin: 14px auto 54px;
    text-align: center;
    font-size: 28px;
    font-weight: 400;
    color: #4e5969;
    line-height: 48px;
  }
}
</style>
